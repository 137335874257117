import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('div',{staticClass:"auth-inner"},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-6"}},[_c('router-link',{staticClass:"brand-logo d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"me-3",attrs:{"src":_vm.appLogo,"max-height":"30px","max-width":"30px","alt":"logo","contain":""}}),_c(VImg,{staticClass:"app-logo me-3",attrs:{"src":require('@/assets/images/svg/oriens-text.svg'),"max-width":"130px","alt":"logo","contain":"","eager":""}})],1)],1),_c(VRow,{staticClass:"auth-row ma-0"},[_c(VCol,{staticClass:"d-none d-lg-block position-relative overflow-hidden pa-0",attrs:{"lg":"8"}},[_c('XyzTransition',{attrs:{"appear":"","xyz":"fade left-3 delay-3"}},[_c('div',{staticClass:"auth-illustrator-wrapper"},[_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"362","src":require(("@/assets/images/misc/mask-v2-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}}),_c(VImg,{staticClass:"auth-tree",attrs:{"height":"226","width":"300","src":require("@/assets/images/misc/tree-4.png")}}),_c('div',{staticClass:"d-flex align-center h-full pa-16 ms-16 pe-0"},[_c('Lottie',{staticStyle:{"max-width":"800px","margin":"0"},attrs:{"options":{
                  animationData: null,
                }}})],1)],1)])],1),_c('XyzTransition',{attrs:{"appear":"","xyz":"fade right-3 delay-10"}},[_c(VCol,{staticClass:"d-flex flex-column auth-bg pa-md-10 pb-0",attrs:{"lg":"4"}},[_c(VRow,{staticClass:"align-center justify-center"},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"6","lg":"12"}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" Selamat datang! 👋🏻 ")]),_c('p',{staticClass:"mb-8"},[_vm._v(" Silahkan login terlebih dahulu. ")]),_c(VForm,{ref:"loginForm",staticClass:"mt-4",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c(VTextField,{staticClass:"mb-6",attrs:{"outlined":"","type":"email","label":"Email","placeholder":"Input email","hide-details":"auto","rules":[_vm.emailValidator]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{staticClass:"mb-2",attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","placeholder":"Password","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"auto"},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"d-flex align-end justify-end text-caption flex-wrap mt-2"},[_c('router-link',{attrs:{"to":"/forgot-password"}},[_vm._v(" Lupa Password? ")])],1),_c(VBtn,{staticClass:"mt-6",attrs:{"block":"","color":"primary","type":"submit","loading":_vm.loading}},[_vm._v(" Login ")]),_c('div',{staticClass:"d-flex align-center justify-center flex-wrap  mt-4"},[_c('router-link',{attrs:{"to":"/register"}},[_vm._v(" Belum punya akun? ")])],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex align-center justify-center mt-4"},[_c('p',[_vm._v(" @2023 CV Pro Bersama ")])])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }