<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <XyzTransition
        appear
        xyz="fade left-3 delay-6"
      >
        <router-link
          to="/"
          class="brand-logo d-flex align-center"
        >
          <v-img
            :src="appLogo"
            max-height="30px"
            max-width="30px"
            alt="logo"
            contain
            class="me-3"
          />

          <v-img
            :src="require('@/assets/images/svg/oriens-text.svg')"
            max-width="130px"
            alt="logo"
            contain
            eager
            class="app-logo me-3"
          />
        </router-link>
      </XyzTransition>
      <!--/ brand logo -->
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <XyzTransition
            appear
            xyz="fade left-3 delay-3"
          >
            <div class="auth-illustrator-wrapper">
              <!-- triangle bg -->
              <img
                height="362"
                class="auth-mask-bg"
                :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              >

              <!-- tree -->
              <v-img
                height="226"
                width="300"
                class="auth-tree"
                src="@/assets/images/misc/tree-4.png"
              />

              <div class="d-flex align-center h-full pa-16 ms-16 pe-0">
                <Lottie
                  :options="{
                    animationData: null,
                  }"
                  style="max-width: 800px; margin: 0"
                />
              </div>
            </div>
          </XyzTransition>
        </v-col>
        <XyzTransition
          appear
          xyz="fade right-3 delay-10"
        >
          <v-col
            lg="4"
            class="d-flex flex-column auth-bg pa-md-10 pb-0"
          >
            <v-row class="align-center justify-center">
              <v-col
                cols="12"
                sm="8"
                md="6"
                lg="12"
              >
                <v-card flat>
                  <v-card-text>
                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                      Selamat datang! 👋🏻
                    </p>
                    <p class="mb-8">
                      Silahkan login terlebih dahulu.
                    </p>
                    <v-form
                      ref="loginForm"
                      class="mt-4"
                      @submit.prevent="login"
                    >
                      <v-text-field
                        v-model="email"
                        outlined
                        type="email"
                        label="Email"
                        placeholder="Input email"
                        hide-details="auto"
                        class="mb-6"
                        :rules="[emailValidator]"
                      />

                      <v-text-field
                        v-model="password"
                        outlined
                        :type="isPasswordVisible ? 'text' : 'password'"
                        label="Password"
                        placeholder="Password"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        hide-details="auto"
                        class="mb-2"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      />

                      <div class="d-flex align-end justify-end text-caption flex-wrap mt-2">
                        <router-link to="/forgot-password">
                          Lupa Password?
                        </router-link>
                      </div>

                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                        :loading="loading"
                      >
                        Login
                      </v-btn>

                      <div class="d-flex align-center justify-center flex-wrap  mt-4">
                        <router-link to="/register">
                          Belum punya akun?
                        </router-link>
                      </div>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <div class="d-flex align-center justify-center mt-4">
              <p>
                @2023 CV Pro Bersama
              </p>
            </div>
          </v-col>
        </XyzTransition>
      </v-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import Lottie from 'vue-lottie'
import themeConfig from '@themeConfig'
import { emailValidator } from '@core/utils/validation'
import store from '@/store'
import router from '@/router'
import breath from '@/assets/lottie/breath.json'

export default {
  components: {
    Lottie,
  },
  setup() {
    const isPasswordVisible = ref(false)

    const loginForm = ref(null)
    const email = ref('')
    const password = ref('')
    const loading = computed(() => store.state.auth.loadingLogin)

    const login = () => {
      if (email.value && password.value) {
        store
          .dispatch('login', {
            email: email.value,
            password: password.value,
          })
          .then(() => {
            Vue.notify({
              title: 'Berhasil login!',
              type: 'success',
              text: 'Selamat datang di OriensCRM.',
            })

            const savedUrl = localStorage.getItem('savedUrl')

            if (savedUrl) {
              localStorage.removeItem('savedUrl')

              router.replace(savedUrl).catch(() => {})
            } else {
              router.replace('/').catch(() => {})
            }
          })
      }
    }

    return {
      isPasswordVisible,
      loginForm,
      email,
      password,
      loading,
      login,
      breath,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      emailValidator,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
